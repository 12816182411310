@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

.pixelated {
  image-rendering: pixelated;
}

.birds {
  margin-bottom: calc(100vh - 448px - 1.5rem);
}
